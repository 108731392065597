import "./_backToTop.scss"
import { Link } from "gatsby"
import { FaArrowUp } from "../icons/index"

import React from 'react'

const BackToTop = ({ whatsapp }) => {

    return (
        <Link to="#top">
            <div className="backToTop" style={{ bottom: (whatsapp === true) ? '50px' : '0px' }} >
                <FaArrowUp color="#212529" size={18} />
            </div>
        </Link >

    )
}

export default BackToTop

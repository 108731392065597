import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Header, Footer } from "../components"
import { AppProvider } from "../context/useAppContext"
import "./_layout.scss"
import Script from "@gumgum/react-script-tag"
import { useGlobalConfig } from "../hooks"
import BackToTop from "../components/BackToTop/backToTop.component"
import { Helmet } from "react-helmet"

const Layout = ({ children, handleLocation, whatsapp }) => {
  const config = useGlobalConfig()
  const scripts = config?.allStrapiGlobalConfig?.nodes.map(item =>
    item.Script.map(script =>
      script.name.toLowerCase() === "analytics" && script.enable === true ? (
        <Helmet>
          <script async defer src={script.src}></script>
          <script async defer>
            {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${script.src.replace(
                      "https://www.googletagmanager.com/gtag/js?id=",
                      ""
                    )}');`}
          </script>
        </Helmet>
      ) : script.name.toLowerCase() === "aivo" && script.enable === true ? (
        <Helmet>
          <script async defer src={script.src}></script>
          <script async defer>
            {` if (typeof window.$aivo !== "undefined") {
              window.$aivo.ready(() => {
              window.$aivo.registerTokenForExternalZendesk('de93c510aaac1a9a8a8529970dad4193')
            })}`}
          </script>
        </Helmet>
      ) : script.enable === true ? (
        <Script
          key={script.name}
          type="text/javascript"
          src={script.src}
          id={script.name}
          defer
          isHydrating={true}
        />
      ) : null
    )
  )
  const codeFaceMeta =
    config?.allStrapiGlobalConfig?.nodes[0]?.facebookMeta ||
    "9qa1ov9qrr8994vi9355sv15rd4sv6"

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [isActive, setIsActive] = useState(false)

  const handleScroll = value => {
    setIsActive(value)
  }

  const classActive = isActive ? "layout-off" : "layout-on"

  return (
    <div className={`layout ${classActive}`}>
      <AppProvider>
        {scripts}
        <Helmet>
          {/* <meta name="facebook-domain-verification" content="s8xkxfcaawge2gog3l8ty7u6bu4iq1" /> */}
          <meta name="facebook-domain-verification" content={codeFaceMeta} />
          {/* <link rel="preconnect" href="https://instagram.com" /> */}
          <link rel="canonical" href="https://21.edu.ar" />
          <link rel="preconnect" href="https://img.youtube.com" />
          <link rel="preconnect" href="https://www.googletagmanager.com" />
          <link
            rel="preconnect"
            href="https://hubspot-public-content.s3.sa-east-1.amazonaws.com"
          />
          {/* <link rel="preconnect" href="https://platform.twitter.com" /> */}
        </Helmet>
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          active={isActive}
          handleScroll={handleScroll}
          handleLocation={handleLocation}
        />
        <main className="layout__children">{children}</main>

        <BackToTop whatsapp={whatsapp} />
        <Footer />
      </AppProvider>
    </div>
  )
}

export default Layout

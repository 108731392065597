import React from "react"
import "./_contactForm.scss"
import HubspotForm from "react-hubspot-form"
import { useScript, useLinkTag } from "../../hooks"

const ContactForm = ({ form, theme, anchor, title, formFields }) => {
  useLinkTag(
    "https://hubspot-public-content.s3.sa-east-1.amazonaws.com/jquery-ui.min.css"
  )
  useScript("https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js")
  useScript(
    "https://hubspot-public-content.s3.sa-east-1.amazonaws.com/jquery.min.js"
  )
  useScript(
    "https://hubspot-public-content.s3.sa-east-1.amazonaws.com/jquery-ui.min.js"
  )
  useScript(
    "https://hubspot-public-content.s3.sa-east-1.amazonaws.com/autocomplete-module.js"
  )
  useScript(
    "https://hubspot-public-content.s3.sa-east-1.amazonaws.com/validations-module.js"
  )

  function modalityValues(string) {
    for (const modality of formFields) {
      if (modality.field === "modalidad_de_interes") {
        for (const value of modality.value) {
          if (value.name === string) {
            return true
          }
        }
        return false
      }
    }
  }

  async function initializeForm() {
    if (formFields !== undefined && formFields !== null) {
      const careerOptions = {
        distance_distributed: modalityValues("Educación Distribuida"),
        distance_distributed_home: modalityValues("Educación Distribuida Home"),
        insite: modalityValues("Presencial"),
        insite_home: modalityValues("Presencial Home"),
        insite_home_rio_iv: modalityValues("Presencial Home Rio IV"),
        insite_distributed: modalityValues("Presencial Distribuida"),

        interest_id: formFields[4].value,
        interest_code: formFields[3].value,
        interest: formFields[2].value,
        program_type: formFields[1].value,
      }

      window["inputsValidations"](false, careerOptions)
    }
  }

  const id =
    anchor !== null && anchor !== undefined && anchor !== "" ? anchor : "form"
  return (
    <div
      id={`${id}`}
      className={`contact-block container-fluid theme-${theme}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>{title}</h2>
          </div>
        </div>
        <div className="col-12 row justify-content-center py-2 m-auto">
          <div className="hubspot-form-wrapper">
            <HubspotForm
              portalId={form.PortalID}
              formId={form.formId}
              onReady={initializeForm}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
